@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.loading-container {
    margin-top: 164px;
    margin-left: auto;
    margin-right: auto;
    width: 337px;
    height: 337px;
    background: rgba(255, 250, 234, 0.8);
    border-radius: 360px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading-text {
    font-size: 15px;
    line-height: 0.5rem;
    margin-top: 20px;
    margin-bottom: 33px;
    color: #727272;
}

.indicator {
    padding-right: 15px;
    visibility: hidden;
}

.indicator:last-child {
    padding-right: 0;
}

.loading1 {
    animation-name: fadeIn;
    animation-duration: 0.5s;
    visibility: visible;
}

.loading2 {
    animation-name: fadeIn;
    animation-duration: 0.5s;
    visibility: visible;
}

.loading3 {
    animation-name: fadeIn;
    animation-duration: 0.5s;
    visibility: visible;
}