.nav-container {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-end;
}

.nav-item {
    text-decoration: none;
    width: 80px;
    height: 70px;
}
