@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.result-container {
    margin: 13px 18px 20px 18px;
    background: rgba(255, 255, 255, 0.49);
    border-radius: 42px;
    height: 500px;
    padding: 20px 32px;
    animation-name: fadeIn;
    animation-duration: 1s;
}

.result-text {
    color: #727272;
    font-size: 20px;
    margin: 0;
}

.result-text-title {
    color: #727272;
    font-size: 50px;
    margin: 5px 0 5px 0;
}

.buttons-container {
    margin-left: 18px;
    margin-right: 18px;
    display: flex;
    justify-content: space-evenly;

    animation-name: fadeIn;
    animation-duration: 2s;
}

.result-button {
    cursor: pointer;
}

.feature-container {
    box-sizing: border-box;
    font-size: 21px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    position: relative;
    padding: 13px;
    
    p {
        color: #515151;
        margin: 0;
        font-size: 21px;
        padding: 1px 0;
    }

    &.id0 {
        background-color: #E6F3A5;
    }

    &.id1 {
        background-color: #CDCDCD;
    }

    &.id2 {
        background-color: #FFB8A9;
    }

    &.id3 {
        background-color: #B3DCED;
    }

    &.id4 {
        background-color: #FAD593;
    }

    &.id5 {
        background-color: #FAD593;
    }

    &.feature1 {
        width: 148px;
        height: 50px;
    }

    &.feature2 {
        width: 120px;
        height: 79px;
        top: 15px;
    }

    &.feature3 {
        width: 120px;
        height: 51px;
        top: 30px;
    }

    &.feature4 {
        width: 126px;
        height: 90px;
        left: 165px;
        top: -180px;
    }

    &.feature5 {
        width: 156px;
        height: 106px;
        left: 135px;
        top: -166px;
    }
}

@mixin result-image($id) {
    margin: 0px auto 20px auto;
    width: 234px;
    height: 179px;
    border-radius: 17px;
    overflow: hidden;
    background: url("../images/NbtiResultPage/result_image" + $id + ".png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.result-image {
    &.id0 {
        @include result-image(0);
    }

    &.id1 {
        @include result-image(1);
    }

    &.id2 {
        @include result-image(2);
    }

    &.id3 {
        @include result-image(3);
    }

    &.id4 {
        @include result-image(4);
    }

    &.id5 {
        @include result-image(5);
    }
}
