.top-logo {
    flex-grow: 1;
}

.background {
    padding-top: 10px;
}

.top-nav {
    margin-left: 20px;
    margin-right: 20px;
    display: flex;
    align-items: center;
}

.empty {
    flex-grow: 0.5;
}

.back-button {
    flex-grow: 1;
    padding-top: 10px;
}

.test-question-container {
    margin: 0 auto;
    width: 353px;
    height: 210px;
    background-image: url("../images/NbtiTestPage/speech_bubble_background.png");
    background-repeat: no-repeat;

    .question-text-container {
        width: 210px;
        height: 120px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 50px;
        padding-right: 5px;

        .question-text {
            font-size: 16px;
            margin: 0;
        }
    }
}

@mixin illust($number) {
    margin: 0 auto;
    width: 150px;
    height: 90px;
    background: url("../images/NbtiTestPage/q" + $number + "_illust.png");
    background-repeat: no-repeat;
    background-position: center;
    transform: translateY(-30px);
}

.question-illust {
    &.q1 {
        @include illust(1);
    }

    &.q2 {
        @include illust(2);
    }

    &.q3 {
        @include illust(3);
    }

    &.q4 {
        @include illust(4);
    }

    &.q5 {
        @include illust(5);
    }

    &.q6 {
        @include illust(6);
    }

    &.q7 {
        @include illust(7);
    }

    &.q8 {
        @include illust(8);
    }

    &.q9 {
        @include illust(9);
    }

    &.q10 {
        @include illust(10);
    }

    &.q11 {
        @include illust(11);
    }
}

.answer-container {
    transform: translateY(-25px);

    .answer {
        margin: 23px 30px 0px 30px;
        padding: 45px 40px;
        background-color: #FFFAEA;
        border-radius: 16px;
        cursor: pointer;
        transition: background-color 0.2s ease-in-out;
        font-size: 16px;

        @media (hover: hover) and (pointer: fine) {
            &:hover {
                background-color: #FFDF96;
            }
        }

        &:active {
            background-color: #FFDF96;
        }

        .answer-text {
            margin: 2px;
        }
    }
}

.question-count {
    display: inline-block;
    padding: 3px 12px;
    margin-top: 20px;
    background-color: #FFFAEA;
    border-radius: 16px;
}