@font-face {
    font-family: "Jua";
    src: url(../fonts/BMJUA_ttf.ttf);
}

@font-face {
    font-family: "Patua";
    src: url(../fonts/PatuaOne-Regular.ttf);
}

// 전체 배경
.background {
    height: 100%;
    min-height: 100vh;
    background-color: #FFF6D6;
    padding-top: 20px;
    padding-bottom: 40px;
}

// 앱 전체 화면 컨테이너
.app-container {
    margin: 0 auto;
    max-width: 390px;
}

// nav 아래 화면 컨테이너
.container {
    margin: 0 auto;
    text-align: center;
    p {
        font-family: "Jua";
    }
}

