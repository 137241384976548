.container {
    margin: 0 auto;
    text-align: center;
}

.map-container {
    position: relative;
    margin-top: 20px;
    margin-bottom: 20px;
}

@keyframes ani{
    0% {transform: translate(0, 0);}
    100% {transform: translate(0, 4px);}
 }  

.booth-map-img {
    width: 347px;
    height: 228px;
}

.day-filter-container {
    display: flex;
    justify-content: space-around;
    margin-bottom: 30px;

    .day-filter-item-container {
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        
        color: #696969;

        &.selected-date {
            color: #FF898D;

            & > hr.underline {
                background-color: #FF898D;
            }
        }
        
        @media (hover: hover) and (pointer: fine) {
            &:hover {
                color: #FF898D;

                & > hr.underline {
                    background-color: #FF898D;
                }
            }
        }
    
        &:active {
            color: #FF898D;

            & > hr.underline {
                background-color: #FF898D;
            }
        }

        .day {
            font-size: 15px;
            margin: 0;
            font-family: 'Patua', cursive;
        }
        
        .dayOfWeek {
            font-size: 25px;
            margin: 0 0 4px 0;
            font-family: 'Patua', cursive;
        }

        hr, .underline {
            transition: all 0.2s ease-in-out;
            margin: 0;
            padding: 0;
            width: 82px;
            height: 4px;
            background-color: #D9D9D9;
            border-width: 0;
            border-radius: 10px;
            opacity: 0.9;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
        }
    }
}

.scroll-container{
    margin: 0 25px 0 25px;
    background-color: #FFFFFF7D;
    padding: 20px 20px;
    border-radius: 16px;
    height : 300px;
    overflow-y: scroll;
}

ul{
    margin:0;
    padding:0;
}
