@font-face {
    font-family: "Jua";
    src: url(../fonts/BMJUA_ttf.ttf);
}

.container{
    margin: 0 auto;
    height: 100%;
}

.pink-memo-container{
    margin: 0 15px 20px 15px;
    background-image: url("../images/GuestbookPage/pinkmemo.png") !important;
    background-repeat: no-repeat;
    background-size:contain;
    padding-bottom:15px;
}

.yellow-memo-container{
    margin: 18px 15px 20px 15px;
    background-image: url("../images/GuestbookPage/yellowmemo.png");
    background-repeat: no-repeat;
    background-size:contain;
    padding-bottom:15px;
}

.green-memo-container{
    margin: 0 15px 20px 15px;
    background-image: url("../images/GuestbookPage/greenmemo.png");
    background-repeat: no-repeat;
    background-size:contain;
    padding-bottom:15px;
}

.blue-memo-container{
    margin: 18px 15px 20px 15px;
    background-image: url("../images/GuestbookPage/bluememo.png");
    background-repeat: no-repeat;
    background-size:contain;
    padding-bottom:15px;
}

.purple-memo-container{
    margin: 0 15px 20px 15px;
    background-image: url("../images/GuestbookPage/purplememo.png");
    background-repeat: no-repeat;
    background-size:contain;
    padding-bottom:15px;
}

.brown-memo-container{
    margin: 18px 15px 20px 15px;
    background-image: url("../images/GuestbookPage/brownmemo.png");
    background-repeat: no-repeat;
    background-size:contain;
    padding-bottom:15px;
}

.pink-ear{
    background-image:url("../images/GuestbookPage/pink_ear.svg")
}

.backbtn-container{
    width: 50px;
    height: 50px;
    float: left;
}

.textbox{
    padding: 10px;
    margin-top:90px;
    margin-bottom:16px;
    margin-left:20px;
    margin-right:20px;
    border:none;
    background-color:#FFF6F1;
    width:300px;
    height: 220px;
    resize: none;
    border-radius: 20px;
    font-family: "Jua";
    font-size: 18px;
    color: #000000;
    text-shadow: none;
    letter-spacing: 1px;
    line-height:30px;

    &.top-cat-box {
        margin-top: 70px;
    }
}
.textbox:focus {
    outline: none;
  }

  .content-container {
    position: relative;
    margin-left: 25px;
    margin-right: 25px;
    margin-top: 30px;
    background-color: #FFFFFF7D;
    padding-top: 45px;
    border-radius: 16px;
    min-height: 90vh;

    .plusbtn-container{
        position:fixed;
        padding: 400px 13rem;
        width:100px;
        z-index: 3;
    }
}
.btn-container{
    border-radius:30%;
}

.nav-item {
    height: 60px;
}

.writing-nav-container{
    display: flex;
    justify-content: space-evenly;
    align-items: flex-end;
    margin-top:20px;
    margin-bottom: 0x;
}

.guide-content {
    margin-left: 25px;
    margin-right: 25px;
    margin-top: 40%;
    margin-bottom: 100%;
    padding: 20px 0px;
}

h2{
    font-family: "Jua";
    font-size: 20px;
    color: #727272;
    letter-spacing: 2px;
    font-weight: normal;
}

.textlength{
    .contentlength{
        padding-top:10px;
        span{
            font-family: "Jua";
            font-size: 16px;
            color: #727272;
            text-shadow: none;
            letter-spacing: 1px;
        }
    }   

    &.top-cat {
        position: relative;
        top: -30px;
    }

    &.middle-cat {
        position: relative;
        top: -20px;
    }
}

//포스트잇
li{
    // img{
    //     position:absolute;
    //     z-index:-2;
    // }
    background-color:#FFD9EB;
    margin: 0 32px 30px 32px;
    padding: 23px 20px 25px 20px;
    border-radius: 10px;
    list-style: none;
    font-family: "Jua";
    font-size: 16px;
    color: #000000;
    letter-spacing: 1px;
    word-break: break-all;
}

.container {
    p .guest-title {
        font-family: "Jua";
        color: #3D1D00;
        letter-spacing: 1px;
}
}

.guestbook-container {
    margin-top: 37px;
}

.plusBtn-container {
    position:fixed;
    width:100px;
    height:100px;
    right:40px;
    top:200px;

}
//게시물 추가 버튼
.plus-btn {
    position: fixed;
    right: 20px;
    width: 136px;
}

//버튼 기본스타일 지움(전역으로)
button{
    border:none;
    background: none;
}

.list-wrapper{
    position:relative;
    padding-bottom: 20px;

    .ear{
        position:absolute;
        top: 2px;
        left:90px;
        // bottom: 1rem;
        z-index:0;
        margin-top:-35px;
        margin-bottom:0;
        margin-right:auto;
        margin-left:auto;
        padding:0;
    }

    li{
        position:relative;
        z-index:1;
    }
    .head{
        position:absolute;
        z-index:0;
        top: 2px;
        right: 40px;
        // bottom: 1rem;
        margin-top:-26px;
        margin-bottom:0;
        padding:0;
    }

}