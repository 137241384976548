.nbti-title-container {
    margin-bottom: 1rem;
}

.nbti-description-container {
    min-height: 403px;
}

.nbti-test-btn-container{
    margin-top: 1.6rem;
}
