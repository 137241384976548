.title-container {
    background-image: url("../images/LandingPage/jelly-beans.svg");
    background-repeat: no-repeat;
    padding-top: 35px;
}

.main-title {
    width: 65%;
    margin-bottom: 5px;
    animation: float 5s ease-in-out infinite;
}

// float animation
@keyframes float {
    0% {
        transform: translatey(0px);
    }
    50% {
        transform: translatey(-8px);
    }
    100% {
        transform: translatey(0px);
    }
}

.sub-title {
    width: 70%;
    margin-bottom: 5px;
}

.date {
    width: 50%;
    margin-bottom: 25px;
}

.board {
    margin-left: 40px;
    margin-right: 40px;
}

.button-container {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.section {
    margin-top: 50px;
    
    .section-content {
        margin-left: 25px;
        margin-right: 25px;
        margin-top: 30px;
        background-color: #FFFFFF7D;
        padding: 35px 35px;
        border-radius: 16px;
        line-height: 1.5rem;

        .section-content-wrapper {
            margin-bottom: 40px;

            .date-container {
                background-color: #FFF6D6;
                border-radius: 16px;
                padding: 5px 15px;
                text-shadow: none;
                color: black;
                font-family: "Jua";
                font-weight: normal;
            }

            .timetable-content {
                margin-bottom: 30px;
                margin-top: 25px;
            }
        }
        .likelion-title-text {
            margin-bottom: 25px;
        }
    }
}

p {
    font-size: 15px;
}

.main-footer {
    margin: 40px 0 20px 0;
    display: flex;
    justify-content: space-evenly;
}

.item-container {
    text-align: center;
    
    .item-logo {
        width: 55px;
        height: 55px;
        margin: 0 auto;
    }
}

.logo-img {
    width: 100px;
    height: 100px;
}

.footer-text {
    font-size: 14px;
    color: #727272;
    line-height: 1rem;
}

.detail-view-btn{
    background-image:url('../images/LandingPage/detail_view_icon.svg');
    background-repeat: no-repeat;
    position: relative;
    top: 2px;
    left: 1px;
    z-index: 1;
}

@keyframes modalShow {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes modalHidden {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    
    // animation: modalShow 0.3s linear;
  }

.hidden {
    display: none;
  }

  .md_overlay {
    background: rgba(0, 0, 0, 0.25);
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .md_content {
    margin: 0 auto;
    max-height: 600px;
    min-width: 290px;
    width: 290px;
    overflow-wrap: normal;
    overflow-y: scroll;
    padding: 0 20px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #FFF6D6;
    border-radius: 15px;
    z-index: 2;
  }
  .md_content::-webkit-scrollbar {
    display:none;
  }
  
  .md_content > p {
    margin: 20px auto 30px auto;
    min-width: 250px;
    padding: 30px 20px;
    background-color: #FFFFFF;
    border-radius: 15px;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;
    color: #7a7a7a;
  }
  
  .md_content > h3 {
    font-family: "Jua";
    padding-top:20px;
    color: #000000;
    opacity:80%;
    font-weight: 700;
    font-size: 30px;
    line-height: 30px;
    margin-top: 10px;
    margin-bottom: 14px;
  }

.md_close{
    position:absolute;
    right:15px;
    top: 10px;
    z-index: 2;
}